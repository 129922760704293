import { ImageSourceHelper } from '@sortlist-frontend/media';

const s3BucketUrl = process.env.NEXT_PUBLIC_S3_BUCKET;

const facebookIcon = ImageSourceHelper.fromUrl(`${s3BucketUrl}/icons/social/facebook.svg`);
const dribbbleIcon = ImageSourceHelper.fromUrl(`${s3BucketUrl}/icons/social/dribbble.svg`);
const vimeoIcon = ImageSourceHelper.fromUrl(`${s3BucketUrl}/icons/social/vimeo.svg`);
const youtubeIcon = ImageSourceHelper.fromUrl(`${s3BucketUrl}/icons/social/youtube.svg`);
const linkedIcon = ImageSourceHelper.fromUrl(`${s3BucketUrl}/icons/social/linkedin.svg`);
const twitterIcon = ImageSourceHelper.fromUrl(`${s3BucketUrl}/icons/social/twitter.svg`);
const behanceIcon = ImageSourceHelper.fromUrl(`${s3BucketUrl}/icons/social/behance.svg`);
const instagramIcon = ImageSourceHelper.fromUrl(`${s3BucketUrl}/icons/social/instagram.svg`);
const pinterestIcon = ImageSourceHelper.fromUrl(`${s3BucketUrl}/icons/social/pinterest.svg`);
const googlePlusIcon = ImageSourceHelper.fromUrl(`${s3BucketUrl}/icons/social/google-plus.svg`);

export type socialMediaIconsListKey = keyof typeof socialMediaIconsList;

export const socialMediaIconsList = {
  facebook: facebookIcon,
  dribbble: dribbbleIcon,
  vimeo: vimeoIcon,
  youtube: youtubeIcon,
  linkedin: linkedIcon,
  twitter: twitterIcon,
  behance: behanceIcon,
  instagram: instagramIcon,
  pinterest: pinterestIcon,
  google_plus: googlePlusIcon,
} as const;

export const isValidSocialMediaKey = (v: string): v is socialMediaIconsListKey => {
  return (v as string) in socialMediaIconsList;
};
