import { TFunction } from '@sortlist-frontend/translation/ssr';
import { ReactElement } from 'react';

import type { GetAgencyAwards } from '_backend/queries/get-agency-awards/get-agency-awards.types';
import type { GetAgencyPortfolio } from '_backend/queries/get-agency-portfolio/get-agency-portfolio.types';
import type { GetAgencyProfile } from '_backend/queries/get-agency-profile/get-agency-profile.types';
import type { GetAgencyReviews } from '_backend/queries/get-agency-reviews/get-agency-reviews.types';
import type { GetAgencyServices } from '_backend/queries/get-agency-services/get-agency-services.types';
import type { GetAgencyTeam } from '_backend/queries/get-agency-team/get-agency-team.types';
import type { MediaAttachment } from '_backend/queries/shared/media-attachment';
import { getGumletImg } from '_core/media/get-media.utils';

import { getPageTitle } from '../seo/profile.seo';

export type Section = {
  id: string;
  label: string;
  icon: ReactElement;
};

export type AgencyStats = {
  awardsCount: number;
  reviewsCount: number;
  reviewsScore: number;
  teamMembersCount: number;
  teamMembersCover: boolean;
  teamStory: boolean;
  worksCount: number;
  servicesStats: {
    minBudget: number;
    maxBudget: number;
    servicesCount: number;
  };
};

export const getActiveSections = (
  sections: Section[],
  agencyStats: AgencyStats,
  agencyData: GetAgencyProfile | undefined,
): Section[] =>
  sections.filter((section) => {
    switch (section.id) {
      case 'portfolio':
        return agencyStats.worksCount > 0;
      case 'reviews':
        return true;
      case 'team':
        return agencyStats.teamMembersCover || agencyStats.teamStory;
      case 'awards':
        return agencyStats.awardsCount > 0;
      case 'services':
        return agencyStats.servicesStats.servicesCount > 0;
      case 'contact':
        return agencyData?.website_url || agencyData?.address;
      case 'about':
        return true;
      case 'similar':
        return agencyData?.plan === 'free';
      default:
        return false;
    }
  });

export const getServiceStats = (servicesData: GetAgencyServices | undefined) => {
  const { services } = servicesData || {};
  if (!services || services.length === 0) return { minBudget: 0, maxBudget: 0, servicesCount: 0 };

  const {
    budget: { min: minBudget },
  } = services.reduce((a, b) => (a.budget.min < b.budget.min ? a : b), { budget: { min: 0 } });
  const {
    budget: { max: maxBudget },
  } = services.reduce((a, b) => (a.budget.max > b.budget.max ? a : b), { budget: { max: 0 } });

  return { minBudget, maxBudget, servicesCount: services.length };
};

export const getSeoPageTitle = (
  top: string[] | string | null,
  name: string,
  locality: string,
  reviewsCount: number,
  t: TFunction,
) => {
  return top
    ? t('agency:profile.seo.metaTitle', { percentage: top })
    : getPageTitle({
        agencyName: name,
        reviewsCount: reviewsCount,
        locality,
        reviewText: t('agency:profile.seo.reviews'),
      });
};

export const getSeoPageImage = (top: string[] | string | null, cover: MediaAttachment | undefined | null) => {
  return cover && top ? getGumletImg(cover, { preferredWidth: 1200, mobile: false }) : undefined;
};

export const getAgencyStats = (
  awards: GetAgencyAwards | undefined,
  reviewsData: GetAgencyReviews | undefined,
  team: GetAgencyTeam | undefined,
  works: GetAgencyPortfolio | undefined,
  services: GetAgencyServices | undefined,
) => {
  return {
    awardsCount: awards?.awards.length || 0,
    reviewsCount: reviewsData?.reviews.length || 0,
    reviewsScore: reviewsData?.global_rating || 0,
    teamMembersCount: team?.member_count || 0,
    teamMembersCover: !!team?.cover,
    teamStory: !!team?.story,
    worksCount: works?.works.length || 0,
    servicesStats: getServiceStats(services),
  };
};
